export interface Location {
  name: string;
  region: string;
  country: string;
  lat: number;
  lon: number;
  tz_id: string;
  localtime_epoch: number;
  localtime: string;
}

export interface Condition {
  text: string;
  icon: string;
  code: number;
}

export interface Current {
  last_updated_epoch: number;
  last_updated: string;
  temp_c: number;
  temp_f: number;
  is_day: number;
  condition: Condition;
  wind_mph: number;
  wind_kph: number;
  wind_degree: number;
  wind_dir: string;
  pressure_mb: number;
  pressure_in: number;
  precip_mm: number;
  precip_in: number;
  humidity: number;
  cloud: number;
  feelslike_c: number;
  feelslike_f: number;
  vis_km: number;
  vis_miles: number;
  uv: number;
  gust_mph: number;
  gust_kph: number;
}

export interface Condition2 {
  text: string;
  icon: string;
  code: number;
}

export interface Day {
  maxtemp_c: number;
  maxtemp_f: number;
  mintemp_c: number;
  mintemp_f: number;
  avgtemp_c: number;
  avgtemp_f: number;
  maxwind_mph: number;
  maxwind_kph: number;
  totalprecip_mm: number;
  totalprecip_in: number;
  avgvis_km: number;
  avgvis_miles: number;
  avghumidity: number;
  daily_will_it_rain: number;
  daily_chance_of_rain: string;
  daily_will_it_snow: number;
  daily_chance_of_snow: string;
  condition: Condition2;
  uv: number;
}

export interface Astro {
  sunrise: string;
  sunset: string;
  moonrise: string;
  moonset: string;
  moon_phase: string;
  moon_illumination: string;
}

export interface Condition3 {
  text: string;
  icon: string;
  code: number;
}

export interface Hour {
  time_epoch: number;
  time: string;
  temp_c: number;
  temp_f: number;
  is_day: number;
  condition: Condition3;
  wind_mph: number;
  wind_kph: number;
  wind_degree: number;
  wind_dir: string;
  pressure_mb: number;
  pressure_in: number;
  precip_mm: number;
  precip_in: number;
  humidity: number;
  cloud: number;
  feelslike_c: number;
  feelslike_f: number;
  windchill_c: number;
  windchill_f: number;
  heatindex_c: number;
  heatindex_f: number;
  dewpoint_c: number;
  dewpoint_f: number;
  will_it_rain: number;
  chance_of_rain: string;
  will_it_snow: number;
  chance_of_snow: string;
  vis_km: number;
  vis_miles: number;
  gust_mph: number;
  gust_kph: number;
  uv: number;
}

export interface Forecastday {
  date: string;
  date_epoch: number;
  day: Day;
  astro: Astro;
  hour: Hour[];
}

export interface Forecast {
  forecastday: Forecastday[];
}

export interface Alerts {
  alert: any[];
}

export interface WeatherApiResponse {
  location: Location;
  current: Current;
  forecast: Forecast;
  alerts: Alerts;
}

export function weatherIcon(code: number, isDay: boolean) {
  if (isDay) {
    switch (code) {
      case 1000:
        return 'day-sunny';
      case 1003:
        return 'day-cloudy';
      case 1006:
        return 'cloudy';
      case 1009:
        return 'cloudy';
      case 1030:
        return 'day-haze';
      case 1063:
        return 'day-showers';
      case 1066:
        return 'day-snow';
      case 1069:
        return 'day-sleet';
      case 1072:
        return 'day-hail';
      case 1087:
        return 'day-thunderstorm';
      case 1114:
        return 'day-snow-wind';
      case 1117:
        return 'day-thunderstorm';
      case 1135:
        return 'day-fog';
      case 1147:
        return 'day-fog';
      case 1150:
        return 'day-sprinkle';
      case 1153:
        return 'day-sprinkle';
      case 1168:
        return 'day-sprinkle';
      case 1171:
        return 'day-sprinkle';
      case 1180:
        return 'day-showers';
      case 1183:
        return 'day-showers';
      case 1186:
        return 'day-rain';
      case 1189:
        return 'day-rain';
      case 1192:
        return 'day-rain';
      case 1195:
        return 'day-rain';
      case 1198:
        return 'day-rain-mix';
      case 1201:
        return 'day-rain-mix';
      case 1204:
        return 'day-sleet';
      case 1207:
        return 'day-sleet';
      case 1210:
        return 'day-snow';
      case 1213:
        return 'day-snow';
      case 1216:
        return 'day-snow';
      case 1219:
        return 'day-snow';
      case 1222:
        return 'day-snow';
      case 1225:
        return 'day-snow';
      case 1237:
        return 'day-hail';
      case 1240:
        return 'day-showers';
      case 1243:
        return 'day-showers';
      case 1246:
        return 'day-showers';
      case 1249:
        return 'day-sleet';
      case 1252:
        return 'day-sleet';
      case 1255:
        return 'day-snow';
      case 1258:
        return 'day-snow';
      case 1261:
        return 'day-hail';
      case 1264:
        return 'day-hail';
      case 1273:
        return 'day-storm-showers';
      case 1276:
        return 'day-thunderstorm';
      case 1279:
        return 'day-snow-thunderstorm';
      case 1282:
        return 'day-snow-thunderstorm';
      default:
        return 'alien';
    }
  } else {
    switch (code) {
      case 1000:
        return 'night-clear';
      case 1003:
        return 'night-cloudy';
      case 1006:
        return 'cloudy';
      case 1009:
        return 'cloudy';
      case 1030:
        return 'night-fog';
      case 1063:
        return 'night-showers';
      case 1066:
        return 'night-snow';
      case 1069:
        return 'night-sleet';
      case 1072:
        return 'night-hail';
      case 1087:
        return 'night-thunderstorm';
      case 1114:
        return 'night-snow-wind';
      case 1117:
        return 'night-thunderstorm';
      case 1135:
        return 'night-fog';
      case 1147:
        return 'night-fog';
      case 1150:
        return 'night-sprinkle';
      case 1153:
        return 'night-sprinkle';
      case 1168:
        return 'night-sprinkle';
      case 1171:
        return 'night-sprinkle';
      case 1180:
        return 'night-showers';
      case 1183:
        return 'night-showers';
      case 1186:
        return 'night-rain';
      case 1189:
        return 'night-rain';
      case 1192:
        return 'night-rain';
      case 1195:
        return 'night-rain';
      case 1198:
        return 'night-rain-mix';
      case 1201:
        return 'night-rain-mix';
      case 1204:
        return 'night-sleet';
      case 1207:
        return 'night-sleet';
      case 1210:
        return 'night-snow';
      case 1213:
        return 'night-snow';
      case 1216:
        return 'night-snow';
      case 1219:
        return 'night-snow';
      case 1222:
        return 'night-snow';
      case 1225:
        return 'night-snow';
      case 1237:
        return 'night-hail';
      case 1240:
        return 'night-showers';
      case 1243:
        return 'night-showers';
      case 1246:
        return 'night-showers';
      case 1249:
        return 'night-sleet';
      case 1252:
        return 'night-sleet';
      case 1255:
        return 'night-snow';
      case 1258:
        return 'night-snow';
      case 1261:
        return 'night-hail';
      case 1264:
        return 'night-hail';
      case 1273:
        return 'night-storm-showers';
      case 1276:
        return 'night-thunderstorm';
      case 1279:
        return 'night-snow-thunderstorm';
      case 1282:
        return 'night-snow-thunderstorm';
      default:
        return 'alien';
    }
  }
}
