import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import {until} from 'lit/directives/until';
import {styleMap} from 'lit/directives/style-map';
import '@iconify/iconify';
import {
  Condition,
  Condition2,
  Condition3,
  Hour,
  WeatherApiResponse,
  weatherIcon,
} from '../apis/WeatherApi';
import {DateTime} from 'luxon';

const API_KEY = '13d7f832aa2d4a218e0213836212402';
const LOCATION = '53.552886951081994, 10.023040568074409';
const WEATER_API_URL = `https://api.weatherapi.com/v1/forecast.json?key=${API_KEY}&q=${LOCATION}&days=3&aqi=no&alerts=no`;

@customElement('weather-forecast')
export class WeatherForecast extends LitElement {
  render() {
    return html`${until(this.getWeather(), html`?`)}`;
  }

  createRenderRoot() {
    return this;
  }

  async getWeather() {
    let res: WeatherApiResponse = await fetch(WEATER_API_URL).then((res) =>
      res.json()
    );

    return html`
      <div class="grid grid-cols-5 items-center lg:space-x-12">
        <div class="col-span-5 space-x-2 grid grid-cols-3 lg:col-span-1">
          ${res.forecast.forecastday.map((forecast) =>
            renderSingleWeatherDay(
              forecast.day.condition,
              forecast.day.maxtemp_c,
              forecast.day.totalprecip_mm,
              DateTime.fromMillis(forecast.date_epoch * 1000).toFormat(
                'dd.MM.'
              ),
              true
            )
          )}
        </div>
        <div class="col-span-5 space-x-2 grid grid-cols-12  lg:col-span-4">
          ${res.forecast.forecastday
            .reduce((acc, day) => [...acc, ...day.hour], [] as Hour[])
            .filter(
              (forecast) =>
                DateTime.fromMillis(forecast.time_epoch * 1000) > DateTime.now()
            )
            .filter((_, i) => i < 12)
            .map((forecast) =>
              renderSingleWeatherDay(
                forecast.condition,
                forecast.temp_c,
                forecast.precip_mm,
                DateTime.fromMillis(forecast.time_epoch * 1000).toFormat(
                  'HH:mm'
                ),
                forecast.is_day === 1
              )
            )}
        </div>
      </div>
    `;
  }
}

function renderSingleWeatherDay(
  condition: Condition | Condition2 | Condition3,
  temp: number,
  precip: number,
  date: string,
  isDay: boolean
) {
  return html`
    <div class="flex flex-col items-center">
      <div>
        <i
          class="h-6 w-6 iconify"
          data-icon="wi:${weatherIcon(condition.code, isDay)}"
        ></i>
      </div>
      <div>${temp}°C</div>
      <div class="text-sm">${precip}mm</div>
      <div class="text-xs">${date}</div>
    </div>
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    'weather-forecast': WeatherForecast;
  }
}
